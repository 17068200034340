import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import Home from "./pages/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Footer from "./components/Footer";
import WalletProvider from "./helper/WalletProvider"
import ReactGA from "react-ga4"


function App() {
  // Set up google analytics
  const TRACKING_ID = "G-E38QN4JYHX"
  ReactGA.initialize(TRACKING_ID)

  useEffect(() => {
    ReactGA.send({hitType:"pageview", page: "/", title:"$SOLTY Homepage"})
  }, []);


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <WalletProvider>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </WalletProvider>
  );
}

export default App;
