import React, { useState } from "react";
import logo from "../images/logo.png";
import mainlogo from "../images/x-logo.png";
import solana from "../images/solana.png";
import billi from "../images/billi.png";
import cvd from "../images/Home2.png";
import homex from "../images/Home.png";
import Copylink from "./Copylink";
import Count from "./Count";
import {
  WalletMultiButton,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import ContractBalance from "./ContractBalance";

export default function Bannerhome() {
  const { publicKey: walletAddres, sendTransaction } = useWallet();
  const mainWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;
  const [amountValue, setAmountValue] = useState("");
  const [soltAmount, setSoltAmount] = useState("");

  const { connection } = useConnection();
  const [txSig, setTxSig] = useState("");
  const link = () => {
    return txSig
      ? `https://explorer.solana.com/tx/${txSig}?cluster=mainnet-beta`
      : "";
  };

  const sendSol = (event) => {
    event.preventDefault();
    if (!connection || !walletAddres) {
      return;
    }
    const transaction = new web3.Transaction();
    const recipientPubKey = new web3.PublicKey(mainWalletAddress);

    const sendSolInstruction = web3.SystemProgram.transfer({
      fromPubkey: walletAddres,
      toPubkey: recipientPubKey,
      lamports: web3.LAMPORTS_PER_SOL * event.target.amount.value,
    });

    transaction.add(sendSolInstruction);

    sendTransaction(transaction, connection).then((sig) => {
      setTxSig(sig);
    });
  };

  return (
    <div>
      <section className="banner">
        <div className="bux2">
          <img src={homex} />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="Anatomy" data-aos="zoom-in">
                <img src={mainlogo} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="vb_xss" id="buynow">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="box-dc" data-aos="fade-up">
                  <div className="solna">
                    <img src={solana} />
                  </div>
                  <h1>
                    $SOLTY
                    <br /> THE <span>RED</span> PANDA POWER presale
                  </h1>
                  <div className="meme-power">
                    <h2>The meme power of $SOLTY on SOLANA</h2>
                  </div>
                  <h3>
                    <span>Presale Funds raised</span>{" "}
                    <span>
                      <ContractBalance />
                    </span>
                    {/* <span>SOLTY</span> */}
                  </h3>
                  <form onSubmit={sendSol}>
                    <div className="fox">
                      <div className="fox-x">
                        <h4>SOL equals</h4>
                        <div className="nmc">
                          <input
                            id="amount"
                            value={amountValue}
                            type="text"
                            placeholder="1"
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Validate input to allow only numbers and one decimal point
                              if (
                                /^\d*\.?\d{0,2}$/.test(inputValue) ||
                                inputValue === ""
                              ) {
                                setAmountValue(inputValue);
                                if (inputValue !== "") {
                                  setSoltAmount(parseFloat(inputValue) * 50000);
                                } else {
                                  setSoltAmount(0);
                                }
                              }
                            }}
                            required
                          />

                          <img src={solana} />
                        </div>
                      </div>
                      <div className="fox-x">
                        <h4>$SOLTY</h4>
                        <div className="nmc">
                          <input
                            type="number"
                            placeholder="50,000"
                            value={soltAmount}
                            onChange={(e) => {
                              setSoltAmount(e.target.value);
                              if (+e.target.value > 0) {
                                setAmountValue(+e.target.value * 0.00002);
                              } else {
                                setAmountValue("0");
                              }
                            }}
                          />
                          <img src={logo} />
                        </div>
                      </div>
                    </div>

                    <div className="cop-x">
                      <h3>Wallet address </h3>
                      <p>If you have any wallet connect issues, please send the wallet address below.</p>
                      <Copylink />
                    </div>
                    <p>
                      Tokens airdropped to purchasing wallet on sale completion
                    </p>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {walletAddres && (
                        <button
                          className="buy-x"
                          disabled={!walletAddres}
                          type="submit"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModal"
                        >
                          BUY NOW
                        </button>
                      )}
                      <div style={{ marginTop: "20px", width: "100%" }}>
                        {walletAddres ? (
                          <div className="disconnect-button">
                            <WalletDisconnectButton />
                          </div>
                        ) : (
                          <WalletMultiButton>BUY NOW</WalletMultiButton>
                        )}
                      </div>
                      {txSig ? (
                        <div>
                          <p>View your transaction on </p>
                          <a href={link()} target="_blank">
                            Solana Explorer
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="bo" data-aos="zoom-out">
                  <img src={billi} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bux">
          <img src={cvd} />
        </div>
      </section>
    </div>
  );
}
