import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import cryptonews from "../images/cryptonews.png";
import bitcoinist from "../images/bitcoinist.png";
import techopedia from "../images/techopedia.png";
import finebold from "../images/finebold.png";
import inside from "../images/inside.png";
import "swiper/css";
import { Autoplay } from "swiper/modules";

export const Clients = () => {
  return (
    <div>
      <section className="clients-slider">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 col-sm-12 col-xl-12 col-xxl-12">
            <div className="small-logo" data-aos="fade-up">
              <h5>Featured In</h5>
            </div>
            <div data-aos="fade-up">
            <Swiper
        slidesPerView={2}
        spaceBetween={30}
        modules={[Autoplay]}
        loop={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        breakpoints={{
          470: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          991: {
            slidesPerView: 3,
          },
          1199: {
            slidesPerView: 4,
          },
        }}
      >
        <SwiperSlide><img src={cryptonews} /></SwiperSlide>
        <SwiperSlide><img src={bitcoinist} /></SwiperSlide>
        <SwiperSlide><img src={techopedia} /></SwiperSlide>
        <SwiperSlide><img src={finebold} /></SwiperSlide>
        <SwiperSlide><img src={inside} /></SwiperSlide>
      </Swiper>
          </div>
          </div>
        </div>
      </div>
      
      </section>  
    </div>
  );
}


