import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { FC, useEffect, useState } from "react";
import * as web3 from "@solana/web3.js";
import { useConnection } from "@solana/wallet-adapter-react";

const ContractBalance = () => {
  const [balance, setBalance] = useState(0);
  const { connection } = useConnection();
  const mainWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;

  const recipientPubKey = new web3.PublicKey(mainWalletAddress);

  useEffect(() => {
    if (!connection || !recipientPubKey) {
      return;
    }

    connection.getBalance(recipientPubKey).then((info) => {
      setBalance(info);
    });
  }, [connection, recipientPubKey]);

  return (
    <div>
      {recipientPubKey ? `${(17000 + +(balance / LAMPORTS_PER_SOL)).toLocaleString(undefined, {maximumFractionDigits:2})} SOL` : ""}
    </div>
  );
};
export default ContractBalance;
